
.carousel-image {
    vertical-align: middle;
    width: 100%;
    height: 100%;
}
.carousel-container {
    position: relative;
    margin: auto;
}

.carousel-item {
    width: 100%;
    height: 100%;
}

.wbpprev, .wbpnext {
    cursor: pointer;
    position: absolute;
    top: 60px;
    width: auto;
    padding: 16px;
    margin-top: 0px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.wbpnext {
    right: 0;
    border-radius: 3px 0 0 3px;
}
.wbpprev {
    left: 0;
    border-radius: 3px 0 0 3px;
}
.wbpprev:hover, .wbpnext:hover {
    background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.carousel-caption-bottom {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}
.carousel-caption-center {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    bottom: 50%;
    top: 45%;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}

.carousel-caption-top {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    top: 8px;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}
.dots {
    margin-top: 10px;
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
}
.slide-number {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

.dot {
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.wbpactive, .dot:hover {
    background-color: #717171;
}

.fadewbp {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}
.pause-icon {
    position: absolute;
    /* top: 38%; */
    left: 15px;
    bottom: 2px;
    /* right: 50%; */
    opacity: 1;
}

.pause {
    -webkit-animation-name: pause;
    -webkit-animation-duration: 0.5s;
    animation-name: pause;
    animation-duration: 0.5s;
}

.bar {
    width: 100%;
    background-color: #ddd;
    position: absolute;
    bottom: 0px;
    border-radius: 0 0 10px;
}

.progress {
    width: 1%;
    height: 5px;
    background-color: #042baa;
}

.thumbnails {
    display: flex;
    margin-top: 10px;
    align-items: center;
    overflow: scroll;
}
.thumbnails::-webkit-scrollbar {
    display: none;
}
.thumbnail {
    margin: 0 5px;
}
.active-thumbnail {
    border: #476ff1 solid 3px;
}
@keyframes pause {
    0% {opacity: .2; }
    90% {opacity: 1; }

}
@-webkit-keyframes pause {
    0% {opacity: .2; }
    90% {opacity: 1;}

}
@-webkit-keyframes fadewbp {
    from {opacity: .4}
    to {opacity: 1}
}

@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}

@media only screen and (max-width: 500px) {
    .wbpprev,
    .wbpnext,
    .carousel-caption-bottom,
    .carousel-caption-center,
    .carousel-caption-top,
    .slide-number
    {
        font-size: 20px !important;
    }
    .dot {
        height: 4px;
        width: 4px;
    }
    .carousel-container{
        max-height: 250px !important;
    }
    .thumbnail {
        max-width: 70px;
    }
    .carousel-caption-bottom{
        bottom: 25px;
    }
}


